import { useAnalytics } from '@/composables/useAnalytics'
export default {
  computed: {
    downloadAppLink() {
      if (navigator?.userAgent?.match(/Android/i)) {
        return 'https://play.google.com/store/apps/details?id=com.stockpro'
      }
      return 'https://apps.apple.com/fr/app/stock-pro/id1450359795'
    },
  },
  methods: {
    trackDownloadLink() {
      const { analytics, events } = useAnalytics()
      analytics.sendEvent(events.SUBMIT_APPLICATION)
    },
  },
}
